<template>
  <div class="personal">
    <div v-for="(item, index) in lists" :key="index">
      <span class="personal-left">
        <img :src="item.img" class="img" />
        <span v-if="item.celebrity" class="VVVV"></span>
        <!-- <div
          v-if="item.celebrity"
          style="    text-align: center;
    
    color: red;
    font-size: 16px;"
        >知识网红</div> -->
      </span>
      <span class="personal-right">
        <span class="name">{{ item.name }}</span>
        <span class="autor" v-if="item.intro">
          <span class="write-icon"></span>
          {{ item.intro }}
        </span>
        <span v-if="item.keyword">
          <span class="autor">领域：</span>
          <!-- v-for="(word,index) in JSON.parse(item.type)" :key="index" -->
          <span class="type">{{ item.keyword }}</span>
        </span>

        <span>用户等级:{{ item.level }}</span>
      </span>
      <!-- <span class="function">
              <span class="iii" v-if="follows=2">+ 关注</span>
              <span v-if="follows=1">已关注</span>
      <span class="iii">邀请回答</span>-->
      <!-- <span  @click="dialogTableVisible = true">私信</span> -->
      <!-- </span> -->
    </div>

    <div class="dialog">
      <!--外层的遮罩 点击事件用来关闭弹窗，isShow控制弹窗显示 隐藏的props-->
      <!-- <el-dialog  title="段浩天"  :visible.sync="dialogTableVisible" center>
      <el-table :data="gridData">
    <el-table-column property="date" label="日期" width="150"></el-table-column>
    <el-table-column property="name" label="姓名" width="200"></el-table-column>
    <el-table-column property="address" label="地址"></el-table-column>
     </el-table>
       <div slot="footer" class="dialog-footer">
    <el-button @click="dialogTableVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
  </div>
      </el-dialog>-->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    lists: {
      type: Array,
      required: true
    }
    //    follows:{
    //         type:Number ,
    //        required:true
    //    }
    //            isShow: {
    //弹窗组件是否显示 默认不显示
    //   type: Boolean,
    //   default: false,
    //   required:true, //必须
    // },
  },
  data() {
    return {
      gridData: [],
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      formLabelWidth: "120px"
    };
  },
  methods: {
    async letter() {
      this.isShow = true;
    }
  }
};
</script>
<style scoped>
.personal {
  width: 100%;
  display: inline-block;
  /* border: 1px solid tomato; */
  height: 150px;
}
.img {
  width: 120px;
  height: 120px;
  border: 0;
  border-radius: 50%;
}
.personal-left {
  width: 130px;
  display: inline-block;
  position: relative;
  /* margin-left: 40px; */
  /* border: 1px solid firebrick; */
}
.iii {
  cursor: pointer;
}
.personal-right {
  margin-top: 20px;
  width: 80%;
  display: inline-block;
  vertical-align: top;
  /* border: 1px solid fuchsia; */
  margin-left: 10px;
}
.personal-right > span {
  width: 100%;
  height: 35px;
  line-height: 35px;
  display: inline-block;
}
.name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
}
.autor {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.type {
  padding: 6px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 100;
  color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(149, 149, 149, 1);
  border-radius: 16px;
  margin-right: 10px;
  background-color: white;
}
/* src\assets\icon\write3.png */
.write-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  /* border: 1px solid red; */
  background-image: url("../../../../../assets/icon/write3.png");
  background-repeat: no-repeat;
  position: relative;
  left: 0px;
  top: 3px;
}
.function {
  width: 250px;
  border: 1px solid red;
  height: 40px;
  display: inline-block;
  vertical-align: bottom;
}
.function > span {
  width: 80px;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 132, 255, 1);
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 132, 255, 1);
  border-radius: 17px;
  padding: 5px;
  margin-left: 25px;
  /* margin-bottom: 10px; */
}

/* 最外层 设置position定位  */
.dialog {
  position: relative;
  color: #2e2c2d;
  font-size: 16px;
}
/* 遮罩 设置背景层，z-index值要足够大确保能覆盖，高度 宽度设置满 做到全屏遮罩 */
.dialog-cover {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* 内容层 z-index要比遮罩大，否则会被遮盖， */
.dialog-content {
  position: fixed;
  top: 35%;
  /* 移动端使用felx布局  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 300;
}
.VVVV {
  color: #fda444;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 100px;
  left: 90px;
  display: inline-block;
  width: 20px;
  height: 20px;
  /* opacity: 0.85; */
  background-size: 100% 100%;
  background-image: url("~@/assets/icon/v.png");
}
</style>
