<template>
    <div class="Details">
        <div class="Details_Line">
        <div class="Details-content" v-for="(item,index) in Details" :key="index">
            <!-- 问题 -->
            <span class="problem" @click="details(item.wdquestionid)">问题：{{item.questionTitle}}</span>
            <!-- 回答 -->
            <!-- {{item.questionContent==undefined?"":item.questionContent.substring(0,100)+"..."}} -->
            <!-- <span class="answer" v-if="item.questionContent==undefined||item.questionContent.length<30"  >答案：{{item.questionContent}}</span>
            <span class="answer" v-if="item.questionContent==undefined||item.questionContent.length>=30&&item.questionContent.length<=100"  >答案：{{item.questionContent.substring(0,30)+"......"}}</span>
            <span class="answer" v-if="item.questionContent==undefined||item.questionContent.length>100&&item.questionContent.length<=500"  >答案：{{item.questionContent.substring(0,200)+"......"}}</span>
            <span class="answer" v-if="item.questionContent==undefined||item.questionContent.length>500&&item.questionContent.length<1000">答案：{{item.questionContent.substring(0,400)+"......"}}</span>
            <span class="answer" v-if="item.questionContent==undefined||item.questionContent.length>1000">答案：{{item.questionContent.substring(0,600)+"......"}}</span>
            <span class="answer" v-if="item.questionContent==undefined||item.questionContent.length>100">答案：{{item.questionContent==undefined?"":item.questionContent.substring(0,100)+"..."}}</span>
             -->
            <span class="answer"  v-if="item.questionContent==undefined||item.questionContent.length<=20">答案：{{item.questionContent}}</span>
            <span class="answer"  v-if="item.questionContent==undefined||item.questionContent.length>20&&item.questionContent.length<50">答案：{{item.questionContent.substring(0,20)+"......"}}</span>
            <span class="answer"  v-if="item.questionContent==undefined||item.questionContent.length>50&&item.questionContent.length<=200">答案：{{item.questionContent.substring(0,30)+"......"}}</span>
            <span class="answer"  v-if="item.questionContent==undefined||item.questionContent.length>200&&item.questionContent.length<500">答案：{{item.questionContent.substring(0,100)+"......"}}</span>
            <span class="answer"  v-if="item.questionContent==undefined||item.questionContent.length>500&&item.questionContent.length<1000">答案：{{item.questionContent.substring(0,300)+"......"}}</span>  
            <span class="answer"  v-if="item.questionContent==undefined||item.questionContent.length>1000">答案：{{item.questionContent.substring(0,600)+"......"}}</span>       
            <!-- 操作 -->
            <span class="words">
                <span>
                    <span class="words_num">{{item.scanNum}}</span>次浏览
                </span>
                <span>
                    <span class="words_num">{{item.fabulous}}</span>人收藏
                </span>
                <span>
                    <span class="words_num">{{item.createTime}}</span> 
                </span>
            </span>
        </div>
        </div>
    </div>
</template>
<script>
import {QUERY,UPDATE,DELETE,INSERT,UPDATES,QUERYED}  from '@/services/dao.js';
export default {
    data(){
        return{
// Details:[
//     ]

        }
    },
    methods:{
            async details(id) {
     // this.$emit('browse');
      console.log("id:"+id)
      let info = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " + id + "}}) { scanNum  }"
      );
      console.log("热度值"+info.data.WDNewQuestion[0].scanNum)
      let anser  =Number( info.data.WDNewQuestion[0].scanNum);
      anser += 1;
       console.log("热度值2:"+anser)
      let dataed = await UPDATES(
        "post",
        "",
        "   update_WdQuestion(where: {id: {_eq: " +
          id +
          "}}, _set: {scanNum: " +
          anser +
          "}) { affected_rows  }"
      );
      if (dataed.data.update_WdQuestion.affected_rows > 0) {
        this.$router.push({
          path: "/writeanswer",
          query: {
            id: id,
          },
        });
      }
    },
    },
props:{
           Details:{
               type:Array,
               required:true
           }
    },
}
</script>
<style scoped>

.words{
font-size:12px;
font-family:Microsoft YaHei;
font-weight:300;
color:rgba(103,103,103,1);
}
.words>span{
    margin-right: 20px;
}
.num{
    border-bottom: 3px solid rgba(0,132,255,1);
    border-radius:2px;
    padding-bottom: 5px;
}
.Details-top{
    width: 100%;
    line-height: 30px;
    height: 30px;
    display: inline-block;
    font-size:16px;
    font-weight:300;
    font-family:Microsoft YaHei;
    color:#0084FF;
    border-bottom: 1px solid rgba(181,181,181,1);
    padding-bottom: 10px;
}
.Details_Line{
    width: 94%;
    /* margin: auto; */
    border-right: 1px dashed #0084FF;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    margin-top: 20px;

}
.Details-content{
    width: 100%;
    margin-top: 10px;
    border-bottom: 1px solid rgba(181,181,181,1);
    padding-bottom: 10px;
}
.Details-content>span{
    width: 100%;
    display: inline-block;
    height: 30px;
    line-height: 30px;
}
.problem{
    font-family: MicrosoftYaHei;
    font-weight:bold;
    font-size:  16px  ;
    cursor: pointer;
}
.words_num{
    margin-right: 3px;
    }
.answer{
    font-family: MicrosoftYaHei;
    /* font-weight:bold; */
    font-size:  14px  ;
     word-wrap:break-word;
    /* cursor: pointer; */
}
</style>