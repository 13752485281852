<template>
  <!-- 团队成员的回答列表 -->
  <div class="content">
    <div class="content_list">
      <!-- 头部 -->
      <div class="head">
        <div class="head1">
          <V-Member :lists="lists"></V-Member>
        </div>
        <span class="function" v-if="followsvisable" @click="followdee()">
          <span class="iii" v-if="follows == 2">+ 关注</span>
          <span v-if="follows == 1">取消关注</span>
          <!-- <span class="iii">邀请回答</span> -->
          <!-- <span  @click="dialogTableVisible = true">私信</span> -->
        </span>
      </div>
      <div class="center">
        <div class="center_left">
          <div class="Details-top">
            回答<span class="num">{{ countproblem }}</span
            >个
          </div>
          <div>
            <V-Details :Details="Details"></V-Details>
          </div>
          <div class="page-nav">
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="hidePageNav"
              :total="totlePage"
              :page-size="pageSize"
              @prev-click="prevPage"
              @next-click="nextPage"
              @current-change="currentPage"
            >
            </el-pagination>
          </div>
        </div>
        <div class="center_right">
          <span class="achievement">个人成就</span>
          <span class="get">
            <span class="Fabulous"></span>
            获得<span>{{ Fabulous }}</span
            >赞
          </span>
          <span class="follow"
            >关注 <span>{{ follow }}</span
            >人
          </span>
          <span class="right_imgs">
            <span
              v-for="(item, index) in image"
              :key="index"
              @click="see(item.id)"
            >
              <span>
                <img :src="item.img" class="right_img" />
              </span>
              <span class="wid">
                <span class="right_name" v-if="item.name.length < 6">{{
                  item.name
                }}</span>
                <span class="right_name" v-if="item.name.length >= 6">{{
                  item.name.substring(0, 6) + "..."
                }}</span>
              </span>
            </span>
            <i v-if="follow > 3" style="font-weight:bold ;text-align:center"
              >......</i
            >
          </span>
          <span class="download">下载 智荟网APP</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DELETE, QUERY, QUERYED, INSERT, UPDATE } from "@/services/dao.js";
import Member from "@/views/wisdom-answer/index/answer-list/member-information/index.vue";
import Details from "@/views/wisdom-answer/index/answer-list/list-details/index.vue";
import { showTimeFormat } from "@/js/common";
export default {
  data() {
    return {
      pageNum: 50,
      pageSize: 10,
      totlePage: 5000,
      hidePageNav: true,
      lists: [],
      Details: [],
      //问题总数
      countproblem: 0,
      //赞
      Fabulous: [],
      //关注人数
      follow: 0,
      number: 0,
      image: [],
      follows: 0, //标识关注状态
      followsvisable: false,
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化

      if (to.query.id != from.query.id) {
        //团员信息
        this.init();
        //个人信息
        this.answer(this.pageSize, 0);
        //回答问题个数
        this.contquestion();
        //显示关注状态
        this.followeds();
      }
    },
  },
  methods: {
    //查看团队成员的回答列表
    async see(id) {
      this.$router.push({
        path: "/answer-list",
        query: {
          id: id,
        },
      });
    },
    ////前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.answer(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.answer(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.answer(this.pageSize, this.pageSize * this.pageNum);
    },
    //显示关注状态
    async followeds() {
      let data = null;
      //获取团队成员的ID
      this.number = this.$route.query.id;
      //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      ///如果没有登陆
      if (
        this.user == null ||
        this.user == "" ||
        this.user < 0 ||
        this.user == "Undefined"
      ) {
        this.follows = 2;
        this.followsvisable = true;
      } else {
        //  followsvisable=true;
        //如果是自己的主页
        if (this.user == this.number) {
          this.followsvisable = false;
        } else {
          this.followsvisable = true;
          data = await QUERYED(
            "post",
            "",
            " WDUserConcern(where: {concernMan: {_eq: " +
              this.user +
              "}, beconcernMan: {_eq: " +
              this.number +
              "}}) {  wdconcernmanid  } "
          );
          if (data.data.WDUserConcern.length > 0) {
            //如果存在关注

            this.follows = 1;
          } else {
            //如果不存在关注
            this.follows = 2;
          }
        }
      }
      //alert(this.follows)
    },
    //关注用户
    async followdee() {
      //   this.follows=1;
      let data = null;
      //获取团队成员的ID
      this.number = this.$route.query.id;
      //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      //判断用户是否登陆
      if (
        this.user == null ||
        this.user == "" ||
        this.user < 0 ||
        this.user == "Undefined"
      ) {
        this.$message({
          type: "warning",
          message: "您还未登录，请您先登录！",
        });
      } else {
        //如果没关注
        if (this.follows == 2) {
          data = await INSERT(
            "post",
            "",
            '     insert_wdConcernMan(objects: {time: "' +
              new Date() +
              '", concernMan: ' +
              this.user +
              ", beconcernMan: " +
              this.number +
              ', ip: "0"}) {affected_rows  }'
          );
          if (data.data.insert_wdConcernMan.affected_rows > 0) {
            this.follows = 1;
          }
        }
        //如果已经关注了
        else if (this.follows == 1) {
          data = await DELETE(
            "post",
            "",
            "    delete_wdConcernMan(where: {concernMan: {_eq: " +
              this.user +
              "}, beconcernMan: {_eq: " +
              this.number +
              "}}) { affected_rows  }"
          );
          if (data.data.delete_wdConcernMan.affected_rows > 0) {
            this.follows = 2;
          }
        }
      }
    },

    // 团队成员的基本信息
    async init() {
      this.number = this.$route.query.id;
      let data = await QUERY(
        "post",
        "",
        "  PCUsers(where: {id: {_eq: " +
          this.number +
          "}}, distinct_on: id) {  img   name   id   intro   industry celebrity level } "
      );
      console.log(data);
      this.lists.splice(0, this.lists.length);
      for (let i = 0; i < data.data.PCUsers.length; i++) {
        this.lists.push({
          id: data.data.PCUsers[i].id,
          level: data.data.PCUsers[i].level,
          celebrity: data.data.PCUsers[i].celebrity,
          img: this.api.LoginURL.concat(data.data.PCUsers[i].img),
          name: data.data.PCUsers[i].name,
          intro: data.data.PCUsers[i].intro,
          keyword: data.data.PCUsers[i].industry,
        });
      }
    },
    ///团队成员的回答信息
    async answer(limit, offset) {
      this.number = this.$route.query.id;
      //alert(this.number)
      let data = await QUERY(
        "post",
        "",
        "  WDUserQuestionAnswerRef( limit: " +
          limit +
          ", offset: " +
          offset +
          ",where: {id: {_eq: " +
          this.number +
          "}})  { wdquestionid  questionTitle     answer scanNum  createTime   fabulous  }   WDUserQuestionAnswerRef_aggregate( where: {id: {_eq: " +
          this.number +
          "}}) {     aggregate {       count     }   }"
      );
      console.log(data);
      this.Details.splice(0, this.Details.length);
      for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
        let data1 = await QUERY(
          "post",
          "",
          "   WDCollect_aggregate(where: {question: {_eq: " +
            data.data.WDUserQuestionAnswerRef[i].wdquestionid +
            "}}) { aggregate {     count    }  }"
        );
        this.Details.push({
          wdquestionid: data.data.WDUserQuestionAnswerRef[i].wdquestionid,
          questionTitle: data.data.WDUserQuestionAnswerRef[i].questionTitle,
          questionContent: data.data.WDUserQuestionAnswerRef[i].answer, //答案
          scanNum: data.data.WDUserQuestionAnswerRef[i].scanNum,
          createTime: showTimeFormat(
            data.data.WDUserQuestionAnswerRef[i].createTime
          ),
          fabulous: data1.data.WDCollect_aggregate.aggregate.count,
        });
        //alert(data.data.WDUserQuestionAnswerRef[i].fabulous)
      }
      //alert(data.data.WDUserQuestionAnswerRef_aggregate.aggregate.count)
      this.totlePage =
        data.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
    },
    async contquestion() {
      this.number = this.$route.query.id;
      //alert(this.number)
      // 回答个数
      let info = await QUERY(
        "post",
        "",
        "   WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: " +
          this.number +
          "}}) { aggregate {  count(columns: wdanswerref)    }  }"
      );
      //alert(info.data.WDUserQuestion_aggregate.aggregate.count)
      this.countproblem =
        info.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
      //赞
      let data2 = await QUERY(
        "post",
        "",
        "    WDAnswerRef_aggregate(where: {user: {_eq: " +
          this.number +
          "}}) {  aggregate {  count(columns: fabulous)   }  }"
      );
      this.Fabulous = data2.data.WDAnswerRef_aggregate.aggregate.count;
      //alert(this.data2)
      ////关注人数
      let data3 = await QUERY(
        "post",
        "",
        "   WDUserConcern_aggregate(where: {concernMan: {_eq: " +
          this.number +
          "}}) { aggregate {  count(columns: id)   }  }"
      );
      this.follow = data3.data.WDUserConcern_aggregate.aggregate.count;
      ///关注人的头像
      let Image = await QUERY(
        "post",
        "",
        "   WDUserConcern(where: {concernMan: {_eq: " +
          this.number +
          "}}, limit: 3) {    id    img  name}"
      );
      this.image.splice(0, this.image.length);
      for (let i = 0; i < Image.data.WDUserConcern.length; i++) {
        this.image.push({
          img: this.api.LoginURL.concat(Image.data.WDUserConcern[i].img),
          name: Image.data.WDUserConcern[i].name,
          id: Image.data.WDUserConcern[i].id,
        });
      }
    },
  },
  created() {
    //团员信息
    this.init();
    //个人信息
    this.answer(this.pageSize, 0);
    //回答问题个数
    this.contquestion();
    //显示关注状态
    this.followeds();
  },
  components: {
    "V-Member": Member,
    "V-Details": Details,
  },
};
</script>
<style scoped>
@import "~@/styles/page/index.css";
.content {
  width: 100%;
  /* border: 1px solid red; */
  display: inline-block;
  background-image: url("../../../../assets/function1.png");
  background-repeat: no-repeat;
  background-size: 100% 1600px;
  /* height: 1000px; */
}
.content_list {
  width: 85%;
  margin: auto;
  /* border: 1px solid green; */
}
.head {
  width: 100%;
  margin-top: 120px;
}
.center {
  width: 100%;
  display: inline-block;
  /* border: 1px solid darkmagenta; */
  background-color: white;
}
.center_left {
  width: 79%;
  display: inline-block;
  padding-bottom: 20px;
  /* border: 1px solid springgreen; */
}
.center_right {
  width: 20%;
  display: inline-block;
  /* border: 1px solid slateblue;  */
  vertical-align: top;
  margin-top: 20px;
}
.center_right > span {
  width: 100%;
  display: inline-block;
  /* height: 50px;
    line-height: 50px; */
  /* border:1px solid red */
}
.right_img {
  display: inline-block;
  text-align: center;
  width: 50px;
  height: 50px;
  margin: auto;
  /* border: 1px solid firebrick; */
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 4px;
}

.right_name {
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 20px;
  height: 20px;
  /* border: 1px solid red; */
}
.iii {
  cursor: pointer;
}
.achievement {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  color: rgba(1, 1, 1, 1);
}
.Fabulous {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/icon/Fabulous.png");
  background-repeat: no-repeat;
  margin-left: 10px;
  background-size: 15px 15px;
  position: relative;
  top: 2px;
}
.get {
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  font-size: 14px;

  font-family: MicrosoftYaHei;
  /* font-weight:700; */
  color: rgb(44, 44, 44);
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(181, 181, 181, 1);
}
.follow {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  color: rgba(1, 1, 1, 1);
  margin-top: 20px;
}
.right_imgs > span {
  display: inline-block;
  height: 80px;
  width: 60px;
  cursor: pointer;

  /* border: 1px solid forestgreen; */
}
.right_imgs > span > span {
  width: 100%;
  /* border: 1px solid sienna; */
  display: inline-block;
  text-align: center;
  height: 53px;
  margin: auto;
}
.right_imgs {
  /* border: 1px solid red; */
  margin-top: 10px;
  height: 70px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(181, 181, 181, 1);
}
.download {
  text-align: right;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 132, 255, 1);
}
.Details-top {
  width: 95%;
  line-height: 30px;
  height: 30px;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  font-family: Microsoft YaHei;
  margin-left: 20px;
  margin-top: 10px;
  color: #0084ff;
  border-bottom: 1px solid rgba(181, 181, 181, 1);
  padding-bottom: 10px;
}
.num {
  border-bottom: 3px solid rgba(0, 132, 255, 1);
  border-radius: 2px;
  padding-bottom: 5px;
}
.page-nav {
  width: 96%;
  display: inline-block;
  /* border:1px solid red */
}
.page-nav .el-pagination {
  text-align: center;
}
.function {
  width: 24%;
  /* border: 1px solid red; */
  height: 40px;
  display: inline-block;
  vertical-align: bottom;
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
}
.function > span {
  width: 80px;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 132, 255, 1);
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 132, 255, 1);
  border-radius: 17px;
  padding: 5px;
  margin-left: 25px;
  /* margin-bottom: 10px; */
}
.head1 {
  /* border: 1px solid red; */
  width: 75%;
  display: inline-block;
}
</style>
